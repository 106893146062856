// Fonts
@import url('https://fonts.googleapis.com/css?family=Francois+One');
@import url('https://fonts.googleapis.com/css?family=Hind:400,700');

$francois: Francois One, sans-serif;
$hind: Hind, sans-serif;

// Variables
@import "variables";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "node_modules/font-awesome/scss/font-awesome";


//Set Body Font
* {
    padding: 0;
    margin: 0;
    color: lighten($black, 20%);
    font-family: $hind;
}

body {
    background-color: $grey;
    height: 100%;
}

.no-padding-left {
    padding-left: 0;
}

.no-padding-right {
    padding-right: 0;
}

.btn-blue {
    color: $white;
    background-color: $blue;
    border-color: $blue;
    border-radius: 0;
    text-transform: uppercase;
    font-family: $francois;
    padding: 10px 30px;
    &:hover {
        color: $light-blue;
    }
}

.topbar {
    background-color: $white;
    ul {
        margin-bottom: 0;
        padding: 30px 20px;
    }
    li {
        display: inline-block;
        list-style-type: none;
        margin: 0 30px;
        text-transform: uppercase;
        color: $blue;
        font-family: $francois;
    }
}

.carousel {
    padding: 0 0;
    margin-bottom: 0;
    height: auto;
    h1 {
        color: $white;
        text-transform: uppercase;
        font-size: 4rem;
    }
    .carousel-inner {
        img {
            width: 100%;
            height: auto;
            @media only screen and (max-width : 480px) {
                height: 0;
            }
        }
    }
    .logo {
        margin: auto;
        width: 300px;
        position: absolute;
        z-index: 10;
        left: 50%;
        margin-left: -150px;
        @media only screen and (max-width : 480px) {
            width: 100%;
            position: inherit;
            left: 0;
            margin-left: 0;
            margin-right: 0;
        }
        img {
            width: 100%;
        }
    }
}

.navbar-toggle {
    float: left;
    margin: 10px;
    .icon-bar {
        background-color: $blue;
        height: 3px;
    }
}
.top-nav {
    .caret {
        color: $white;
    }
    .navbar-nav {
        background-color: darken($blue, 10%);
        margin: 0;
        @media only screen and (max-width : 768px) {
            padding: 10px 5px;
        }
        .active {
            .dropdown-menu {
                li {
                    a {
                        color: $white;
                    }
                }
            }
            a {
                color: $light-blue;
                &:hover, &:visited, &:active, &:focus {
                    color: $light-blue;
                }
                .caret {
                    color: $light-blue;
                }
            }
        }
        li {
            a {
                color: $white;
                font-family: $francois;
                text-transform: uppercase;
                font-size: 1.6rem;
                &:hover, &:focus, &:active {
                    background-color: darken($blue, 10%);
                    color: $light-blue;
                }
                &:visited {
                    background-color: darken($blue, 10%);
                }
            }
        }
        .dropdown-menu {
            li {
                a {
                    color: $white;
                    text-transform: none;
                    font-family: $hind;
                }
            }
        }
    }
}

.navbar .navbar-nav {
    display: inline-block;
    float: none;
    .caret {
        color: $white;
    }
    .open {
        a {
            background-color: $light-blue !important;
            color: $white !important;
        }
    }
    .active {
        a {
            background-color: $blue;
            color: $light-blue;
            &:hover, &:visited, &:active, &:focus {
                background-color: $blue;
                color: $light-blue;
            }
        }
        .caret {
            color: $light-blue;
        }
    }
    li {
        display: inline-block;
        list-style: none;
        margin: 0;
        border-right: 1px solid lighten($blue, 20%);
        &:last-of-type {
            border-right: none;
        }
        a {
            font-family: $francois;
            text-decoration: none;
            background-color: $blue;
            display: inline-block;
            margin: 0;
            padding: 15px 45px;
            font-size: 1.6rem;
            text-transform: uppercase;
            color: $white;
            &:hover, &:focus, &:active {
                background-color: $blue;
                color: $light-blue;
                .caret {
                    color: $light-blue;
                }
            }
        }
    }
    .dropdown-menu {
        padding: 5px 2px;
        background-color: $light-blue;
        li {
            border: none;
        }
        a {
            color: $white;
            text-transform: none;
            font-family: $hind;
            padding: 6px 42px;
            font-size: 1.5rem;
            background-color: $light-blue;
        }
    }
    @media only screen and (max-width : 768px) {
        padding: 0;
        margin-bottom: 20px;
    }
}

.navbar .navbar-collapse {
    text-align: center;
}

.navbar {
    margin-top: -25px;
    min-height: 50px;
    margin-bottom: 0;
    border:none;
    background-color: transparent;
}

.button-box {
    margin-top: 20px;
}

.button-box {
    background-color: $white;
    padding: 20px;
    margin-bottom: 20px;
    h3 {
        margin: 0 0 20px 0;
        color: $blue;
        font-family: $francois;
    }
}

.content-box {
    background-color: $white;
    padding: 20px;
    @media only screen and (max-width : 768px) {
        margin-bottom: 20px;
    }
    h3 {
        margin: 0 0 20px 0;
        color: $blue;
        font-weight: 600;
        font-size: 1.6rem;
        font-family: $hind;
    }
    .home-title {
        font-weight: 400;
        font-size: 2.4rem;
        font-family: $francois;
    }
    ul {
        margin-left: 20px;
    }
}

.page-content {
    margin: 40px 0 20px 0;
    @media only screen and (max-width : 768px) {
        margin: 0 0 20px 0;
    }
    @media only screen and (max-width : 480px) {
        margin: 20px 0 20px 0;
    }
}

.left-content {}
.right-content {}

.title-box {
    background-color: $blue;
    padding: 20px 40px;
    h1 {
        margin: 0;
        color: $white;
        text-transform: uppercase;
        font-family: $francois;
        font-size: 2.8rem;
    }
}

.blue-box {
    background-color: $blue;
    padding: 20px;
    margin-bottom: 20px;
    h2 {
        margin: 0 0 10px 0;
        color: white;
        font-size: 7.5rem;
        font-weight: 600;
        font-family: $hind;
    }
    p {
        color: $white;
    }
    .location {
        color: $light-blue;
    }
}

.map-box {
    background-color: $blue;
    padding: 60px 20px;
    a {
        color: $white;
        text-decoration: none;
        font-family: $francois;
        &:hover {
            text-decoration: underline;
        }
    }
    img {
        margin: 8px 0 0 0;
        @media only screen and (max-width : 480px) {
            margin: 20px 0;
        }
    }
    p {
        font-family: $francois;
        color: $white;
        text-transform: uppercase;
        font-size: 2.2rem;
        margin-bottom: 0;
        @media only screen and (max-width : 768px) {
            width: 50%;
            float: left;
        }
    }
    @media only screen and (max-width : 768px) {
        padding: 40px 20px;
    }
}

footer {
    color: $white;
    background-color: $white;
    .grey-bar {
        background-color: darken($grey, 30%);
        padding: 10px 0;
        p {
            color: $white;
            margin-bottom: 0;
        }
    }
    .logo-bar {
        padding: 40px 0;
        img {
            height: 80px;
            display: inline-block;
            @media only screen and (max-width: 1024px) {
                height: 60px;
            }
            @media only screen and (max-width: 768px) {
                height: 100px;
            }
            @media only screen and (max-width: 480px) {
                margin-top: 20px;
                height: 80px;
            }
            @media only screen and (max-width: 320px) {
                height: 70px;
            }
        }
        @media only screen and (max-width : 768px) {
            padding: 10px;
        }
    }
}
.footer-sub-logo {
    font-family: $francois;
    color: $white;
    text-transform: uppercase;
    font-size: 2.2rem;
    margin-bottom: 0;
    @media only screen and (max-width : 768px) {
        width: 50%;
        float: left;
    }
}

.number-column {
    display: inline-block;
    width: 40px;
    height: 62px;
    overflow: hidden;
    position: relative;
}
.digit-wrap {
    position: absolute;
    bottom: -8px;
    transition: bottom ease-in 2.5s;
}
.digit {
    width: 100%;
    display: block;
    text-align: center;
    line-height: 55px;
    padding-top: 8.1px;
    color: white;
}
.logo-bar-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    @media only screen and (min-width : 1200px) {
        margin-right: 8.33333%;

        & > div {
            min-width: calc(20% - 15px);
            max-width: calc(20% - 15px);
        }
    }
}
.logo-bar-row--second {
    @media only screen and (min-width : 768px) {
        margin-top: 20px;
    }
}